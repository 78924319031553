body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  align-content: space-between;
  text-align: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app {
  background-color: whitesmoke;
  --amplify-components-heading-color: #002266;
  --amplify-components-text-color: #002266;
}

.head {
  display:flex;
  flex-direction: column;
  align-items: center;
  padding-top: 3rem;
  background-image: linear-gradient(rgba(133,82,255,0.13), whitesmoke);
  --amplify-components-divider-label-color: rgba(133,82,255,1);
  --amplify-components-divider-label-background-color: whitesmoke;
}

.view-main-image {
  margin-top: 3rem;
  padding-bottom: 3rem;
  display:flex;
  flex-direction: column;
  align-items: center;
  background-image: linear-gradient(whitesmoke, lightskyblue);
  --amplify-components-card-outlined-background-color: rgba(133,82,255,0.13);
}

.view-gradient-finisher {
  display:flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 3rem;
  background-image: linear-gradient(lightskyblue, rgba(133,82,255,0.13));
}

.view-gradient-whiteout {
  background-image: linear-gradient(rgba(133,82,255,0.13), whitesmoke);
  padding: 1rem 0;
}

.film {
  background-color: rgba(0,22,66,0.9);
  background-image: url('../public/logo.png');
  background-position: center;
  background-size: contain;
  padding: .01rem 0;
}

.view-storage-manager {
  --amplify-components-storagemanager-dropzone-background-color: null;
  --amplify-components-storagemanager-dropzone-border-color: white;
  --amplify-components-storagemanager-dropzone-text-color: #002266;
  --amplify-components-storagemanager-dropzone-icon-color: #002266;
  --amplify-components-storagemanager-dropzone-text-font-weight: 400;
  --amplify-components-storagemanager-filelist-flex-direction: column-reverse;
  --amplify-components-button-primary-background-color: black;
  background-image: linear-gradient(whitesmoke, white , whitesmoke);
}

.upload-container {
  --amplify-components-flex-flex-wrap: wrap;
  --amplify-components-flex-justify-content: center;
  overflow: clip;
}

.bg-memories {
  padding: 5rem 3rem 3rem 3rem;
  font-weight: 400;
  --amplify-components-button-color: null;
  background-image: linear-gradient(whitesmoke, rgba(133,82,255,0.13));
}

.bg-collection {
  display: flex;
  background-image: linear-gradient(rgba(133,82,255,0.13), whitesmoke);
}

.bg-signout {
  background-image: linear-gradient(whitesmoke, lightskyblue);
}
